import api from "@/services/api";


const alerta = {
  namespaced: true,
  state: {
    tipo: {
      options: [
        { option: "Recadastramento" },
        { option: "Troca de Hidrômetros" },
      ],
      selected: "Recadastramento"
    },
    previsao: '',
    bairros: [],
    mensagem: "",
    disabled: false,
  },
  getters: {
    getterTipo: (state) => state.tipo,
    getterPrevisao: (state) => state.previsao,
    getterBairros: (state) => state.bairros,
    getterMensagem: (state) => state.mensagem,
    getterDisabled: (state) => state.disabled

  },
  mutations: {
    CHANGE_SELECTED_TIPO: (state, value) => {
      state.tipo.selected = value.target.value;
    },
    CHANGE_SELECTED_PREVISAO: (state, value) => {
      state.previsao = value;
    },
    CHANGE_SELECTED_BAIRRO: (state, value) => {
      state.bairros = value
      // console.log(value);
    }
  },
  actions: {
    async send({ commit, state }) {
      if (!state.previsao) return alert("Campo 'Data/Previsão' obrigatório.")
      if (state.bairros.length <= 0) return alert("Campo 'Bairros' obrigatório.")

      try {
        state.disabled = true;
        await api.post("", {
          tipo: state.tipo.selected,
          previsao_data: state.previsao,
          bairros: state.bairros
        });
        state.mensagem = "Alerta enviado com sucesso!";
        setTimeout(() => {
          state.mensagem = "";
          location.reload();
        }, 8000);
        clearTimeout();
        state.disabled = false;
        window.scrollTo({ top: 0 });
      } catch (error) {
        console.log(error);
        state.mensagem = "Algo aconteceu";
        setTimeout(() => {
          state.mensagem = "";
          location.reload();
        }, 20000);
        clearTimeout();
        state.disabled = false;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
  },
};

export default alerta;
