<template>
    <div class="container">
        <div class="row " style="max-width: 478px">
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Tipo</label><br>
                    <select class="form-select input-select" @input="CHANGE_SELECTED_TIPO">
                        <option v-for="(tipo, index) in getterTipo.options" :key="index" :value="tipo.option">
                            {{ tipo.option }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Data/Previsão</label><br>
                    <div class="my-4">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                checked @click="value = false">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Informar Data
                            </label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                @click="value = true">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Informar Dias
                            </label>
                        </div>
                    </div>

                    <div v-if="value === false">
                        <input type="date" class="form-control" id="date" @input="updatePrevisao" />
                    </div>

                    <div v-if="value === true">
                        <strong>Próximos <input type="number" @input="updatePrevisao"> dias.
                        </strong>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Bairros</label><br>
                    <VueMultiselect v-model="selected" :options="options" :multiple="true" placeholder="Selecione">
                    </VueMultiselect>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import VueMultiselect from 'vue-multiselect'
import axios from "axios";


export default {
    name: 'CardOpcoesAlertas',
    components: { VueMultiselect },
    data() {
        return {
            selected: [],
            options: [],
            value: false,
        }
    },
    watch: {
        selected(newValue, oldValue) {
            this.$store.commit('alerta/CHANGE_SELECTED_BAIRRO', newValue)
        }
    },
    created() {
        this.getBairros()
    },
    computed: {
        ...mapGetters('alerta', [
            "getterTipo",
            "getterPrevisao",
            "getterBairros"
        ]),
    },
    methods: {
        ...mapMutations('alerta', [
            "CHANGE_SELECTED_TIPO",
            "CHANGE_SELECTED_PREVISAO",
            "CHANGE_SELECTED_BAIRRO"
        ]),

        async getBairros() {
            const request = await axios.get('https://dmae-webapi-v2.poa.appdigital.tec.br/api/address', { headers: { "x-api-key": "ba301ed1-a999-4256-bc54-dbbdeee2869b"}})
            const { data } = await request.data
            this.options = data
        },
        updatePrevisao(e) {
            if (!this.value) {
                return this.$store.commit('alerta/CHANGE_SELECTED_PREVISAO', `em: ${e.target.value.split('-').reverse().join('/')}`)
            }
            return this.$store.commit('alerta/CHANGE_SELECTED_PREVISAO', `nos próximos ${e.target.value} dias`)
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>